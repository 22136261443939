@import '../../global/refs.scss';

.input {
  width: 100%;
  height: 40px;
  position: relative;
  border-bottom: 2px solid $white;
  margin-top: 55px;

  &.textarea {
    height: 80px;
    padding-top: 5px;

    @media(min-width: $mq-large) {
      height: 100px;
    }
  }

  @media(min-width: $mq-medium) {
    height: 45px;
  }

  @media(min-width: $mq-large) {
    height: 50px;
  }

  .input-label {
    @include text;

    position: absolute;
    bottom: 5px;
    pointer-events: none;
    transition: all .3s ease-in-out;

    @media(min-width: $mq-large) {
      font-size: 0.9rem;
    }
    
    span {
      font-style: italic;
      font-weight: $medium;
    }
    
    &.focused {
      transform: scale(0.8);
      transform-origin: top left;
      bottom: calc(100% - .5rem);
    }
  }
  
  .input-field {
    @include text;
    
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    box-shadow: 0;
    outline: 0;
    font-weight: $medium;
    
    @media(min-width: $mq-large) {
      font-size: 0.9rem;
    }
  }
}