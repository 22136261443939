@import '../../global/refs.scss';

.contact {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 30px;

  @media(min-width: $mq-medium) {
    padding-bottom: 40px;
  }

  @media(min-width: $mq-large) {
    padding-bottom: 80px;
  }

  @media(min-width: $mq-super-large) {
    padding-bottom: 160px;
  }
}

.contact-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  @media(min-width: $mq-large) {
    align-items: flex-start;
    width: 80%;
  }

  .contact-title {
    @include title;

    width: 100%;

    @media(min-width: $mq-large) {
      width: 50%;
    }
  }

  .contact-images {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    @media(min-width: $mq-medium) {
      margin-top: 20px;
    }

    @media(min-width: $mq-large) {
      position: absolute;
      right: 0;
      margin-bottom: 60px;
    }

    .contact-images-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      @media(min-width: $mq-large) {
        flex-direction: column;
      }

      img {
        width: 16vw;
        margin: 2px;
        max-width: 100px;

        @media(min-width: $mq-medium) {
          margin: 3px;
        }

        @media(min-width: $mq-large) {
          margin: 6px;
          width: calc(15vw - 12px);
          max-width: 240px;
        }

        @media(min-width: $mq-super-large) {
          margin: 8px;
          width: calc(15vw - 16px);
          max-width: 280px;
        }
      }
    }    
  }

  .contact-form {
    width: 100%;
    max-width: 640px;

    @media(min-width: $mq-large) {
      width: 50%;
    }
  }

  .contact-submit {
    @include text;

    width: 100%;
    background-color: $pink;
    border: 0;
    border-radius: 8px;
    padding: 8px 20px;
    margin-top: 55px;
    font-weight: $bold;
    font-style: italic;
    letter-spacing: -1px;
    cursor: pointer;
    outline: 0;
  }

  .contact-submit:hover {
    transition: 0.3s;
    transform: scale(1.02);
  }
}