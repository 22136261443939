// ========== SIZES ==========
$mq-super-large: 1280px;
$mq-large: 1024px;
$mq-medium: 768px;
$mq-small: 480px;

// ========== COLORS ==========
$black-bg: #131214;
$black: #000000;
$pink: #EE2073;
$white: #FFFFFF;

// ========== FONT STYLES ==========
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;

@mixin giant-title {
  font-weight: $bold;
  font-style: italic;

  font-size: 2rem;
  line-height: 2.8rem;
  letter-spacing: -2px;

  @media(min-width: $mq-small) {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }

  @media(min-width: $mq-medium) {
    font-size: 3.6rem;
    line-height: 4.8rem;
    letter-spacing: -3px;
  }

  @media(min-width: $mq-large) {
    font-size: 4.2rem;
    line-height: 5.2rem;
  }

  @media(min-width: $mq-super-large) {
    font-size: 5.4rem;
    line-height: 6.4rem;
    letter-spacing: -4px;
  }
}

@mixin title {
  font-weight: $bold;
  font-style: italic;

  font-size: 2.4rem;
  line-height: 3.2rem;
  letter-spacing: -2px;

  @media(min-width: $mq-small) {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }

  @media(min-width: $mq-medium) {
    font-size: 3.2rem;
    line-height: 4rem;
    letter-spacing: -3px;
  }

  @media(min-width: $mq-large) {
    font-size: 3.6rem;
    line-height: 4.6rem;
  }

  @media(min-width: $mq-super-large) {
    font-size: 4rem;
    line-height: 5rem;
    letter-spacing: -4px;
  }
}

@mixin subtitle {
  font-weight: $semi-bold;
  font-size: 1.4rem;
  line-height: 2.2rem;

  @media(min-width: $mq-small) {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  @media(min-width: $mq-medium) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  @media(min-width: $mq-large) {
    font-size: 1.9rem;
    line-height: 3rem;
  }

  @media(min-width: $mq-super-large) {
    font-size: 2rem;
    line-height: 3.2rem;
  }
}

@mixin text {
  font-weight: $regular;
  font-size: 1rem;
  line-height: 1.6rem;

  @media(min-width: $mq-large) {
    font-size: 1.1rem;
    line-height: 1.8rem;
    letter-spacing: 1px;
  }

  @media(min-width: $mq-super-large) {
    font-size: 1.2rem;
    line-height: 2rem;
    letter-spacing: 2px;
  }
}
