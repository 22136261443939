@import '../../global/refs.scss';

.footer {
  width: 100%;

  img {
    width: 100%;
    margin-bottom: -5px;
  }
}
