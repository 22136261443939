@import '../../global/refs.scss';

.brain-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 30px;

  @media(min-width: $mq-medium) {
    padding-top: 80px;
    padding-bottom: 50px;
  }

  @media(min-width: $mq-large) {
    padding-top: 100px;
    padding-bottom: 90px;
  }

  @media(min-width: $mq-super-large) {
    padding-top: 120px;
  }
}

.brain-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(min-width: $mq-large) {
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
  }

  .brain-text-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    @media(min-width: $mq-large) {
      width: 45%;
    }
    
    p {
      @include text;
      line-height: 2.1rem !important;
      width: 80%;
      
      @media(min-width: $mq-large) {
        width: 100%;
        line-height: 2.4rem !important;
      }
    }
  }
  
  .brain-image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    
    @media(min-width: $mq-large) {
      width: 45%;
    }
  
    img {
      width: 100%;
      max-width: 420px;

      @media(min-width: $mq-large) {
        max-width: 680px;
      }
    }
  }  
}