@import '../../global/refs.scss';

.newsletter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 1rem;

  @media(min-width: $mq-large) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  @media(min-width: $mq-super-large) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.newsletter-content-container {
  width: 85%;
  position: relative;

  @media(min-width: $mq-large) {
    width: 80%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-end;
  }
  
  h1 {
    @include title;

    @media(min-width: $mq-large) {
      margin-bottom: 0;
    }
  }

  .input {
    @media(min-width: $mq-large) {
      width: 42% !important;
    }

    label {
      width: fit-content !important;
    }
  }
}

@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

.newsletter-submit-button {
  position: absolute;
  right: 0;
  bottom: 0;
  animation-name: fade-in;
	animation-fill-mode: both;
  animation-duration: .5s;
  background-color: transparent;
  border: 0;
  outline: 0;
  -webkit-box-shadow: 0;
  -moz-box-shadow: 0;
  box-shadow: 0;
  margin-bottom: 6px;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  
  @media(min-width: $mq-large) {
  }

  img {
    background: transparent;
    width: 2.2rem;
  }
}

.newsletter-disable {
  animation-name: fade-out;
	animation-fill-mode: both;
	animation-duration: .5s;
}
