// ========== IMPORTS ==========
@import-normalize;
@import './refs.scss';


// ========== IMPORT FONTS ==========
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  width: 100%;
  border: 0;
  margin: 0;
  background-color: $black-bg;
  background-image: url('./assets/stars.png');
  background-size: 100%;
  color: $white;
  font-family: 'Montserrat', sans-serif;
}

input, textarea, button {
  color: $white;
  font-family: 'Montserrat', sans-serif;
  padding: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $white !important;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $white !important;
}

button {
  cursor: pointer;
}
