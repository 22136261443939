@import '../../global/refs.scss';

@keyframes in-left {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@keyframes out-left {
  0% {
    left: 0;
  }

  100% {
    left: -100%;
  }
}

@keyframes in-right {
  0% {
    right: -100%;
  }

  100% {
    right: 0;
  }
}

@keyframes out-right {
  0% {
    right: 0;
  }

  100% {
    right: -100%;
  }
}

@keyframes fade-out-nav {
  0% {
    height: 100vh;
  }

  99.99% {
    height: 100vh;
  }

  100% {
    height: 0;
  }
}

.nav {
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  transition: background-color .3s linear;

  &.opened {
    background-color: $black;
    height: 100%;
    width: 100%;
  }
  
  &.closed {
    background-color: transparent;
    width: 100%;
    height: 100%;
    pointer-events: none;
    // animation: fade-out-nav .3s linear;
  }
}

.nav-button {
  margin-top: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  -webkit-tap-highlight-color: transparent;
  position: fixed;
  top: 0;
  left: 30px;
  z-index: 20;
  margin-left: 0;
  
  &:focus {
    outline: 0;
  }
  
  @media(min-width: $mq-small) {
    margin-top: 30px;
    margin-left: 0;
  }
  
  @media(min-width: $mq-medium) {
    margin-top: 40px;
    margin-left: 2%;
  }
  
  @media(min-width: $mq-large) {
    margin-top: 80px;
    margin-left: 3%;
  }
  
  .bar {
    margin-top: 5px;
    margin-bottom: 5px;
    width: 36px;
    height: 2px;
    border-radius: 10px;
    background-color: $white;
    transition: all .3s ease-in;

    @media(min-width: $mq-large) {
      margin-top: 6px;
      margin-bottom: 6px;
      width: 44px;
    }

    &.second {
      width: 20px;

      @media(min-width: $mq-large) {
        width: 24px;
      }
    }
  }

  &.opened {
    .bar {
      &.first {
        transform: translateY(12px) rotate(135deg);

        @media(min-width: $mq-large) {
          transform: translateY(14px) rotate(135deg);
        }
      }

      &.second {
        opacity: 0;
      }

      &.third {
        transform: translateY(-12px) rotate(45deg);

        @media(min-width: $mq-large) {
          transform: translateY(-14px) rotate(45deg);
        }
      }
    }
  }
}

.nav-content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  display: none;

  &.opened {
    display: flex;
  }
  
  &.closed {
    // Maybe add animation to closing?
  }

  .nav-content-list {
    width: 80%;
    flex-grow: 1;
    padding-left: 0;
    padding-bottom: 80px;
    padding-top: 90px;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @media(min-width: $mq-medium) {
      padding-top: 100px;
    }

    @media(min-width: $mq-large) {
      padding-top: 140px;
      padding-bottom: 120px;
    }

    @media(min-width: $mq-super-large) {
      padding-top: 160px;
    }

    .nav-item {
      margin-top: 20px;
      width: 100%;
      position: relative;
      cursor: pointer;

      &:hover {
        .fill {
          clip-path: circle(75%);
        }
      }

      @media(min-width: $mq-large) {
        &:nth-child(2) {
          margin-left: 15vw;
        }
        
        &:nth-child(3) {
          margin-left: 30vw;
        }

        &:nth-child(4) {
          margin-left: 45vw;
        }
        &:nth-child(5) {
          margin-left: 60vw;
        }
      }


      .nav-item-link {
        @include title;
        text-decoration: none;
        color: transparent;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: $white;
      }

      .fill {
        position: absolute;
        color: $pink;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: $pink;
        top: 0;
        left: 0;
        transition: clip-path .2s ease-in;
        clip-path: circle(0);
        pointer-events: none;
      }

      &.left {
        &.opened {
          animation: in-left .3s ease-in;
        }

        &.closed {
          animation: out-left .3s ease-in;
        }
      }

      &.right {
        &.opened {
          animation: in-right .3s ease-in;
        }

        &.closed {
          animation: out-right .3s ease-in;
        }
      }
    }
  }
}
