@import '../../global/refs.scss';

.our-orbit-section {
  padding-top: 10rem;
  width: 100%;
  background-image: url('./assets/orbit-background.svg');
  background-size: cover;
  background-position-x: center;
  background-position-y: top;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
  padding-bottom: 10rem;

  @media(min-width: $mq-small) {
    padding-top: 14rem;
  }

  @media(min-width: $mq-large) {
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 5rem;
    padding-top: 8rem;
  }

  @media(min-width: $mq-super-large) {
    padding-top: 14rem;
  }
  
  h1 {
    @include title;
    margin-bottom: 5rem;
    width: 85%;
  }
  
  .our-orbit-content-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @media(min-width: $mq-large) {
      align-items: flex-start;
      width: fit-content;
      margin-left: 7.5%;
    }

    img {
      width: 80%;
      margin-bottom: 5rem;

      @media(min-width: $mq-large) {
        width: 130%;
      }

      @media(min-width: $mq-super-large) {
        width: 120%;
      }
    }
  }


  .our-orbit-bottom-planet {
    transform: rotate(90deg) translateX(40%);
    position: absolute;
    bottom: 0;
    height: 30rem;
    width: auto !important;
    
    @media(min-width: $mq-large) {
      transform: rotate(0);
      position: static;
      height: 50rem;
    }
  }

  .orbit-mobile {
    @media(min-width: $mq-medium) {
      display: none;
    }

    @media(min-width: $mq-large) {
      display: none;
    }
  }

  .orbit-ipad {
    display: none;

    @media(min-width: $mq-medium) {
      display: block;
    }

    @media(min-width: $mq-large) {
      display: none;
    }
  }

  .orbit-web {
    display: none;

    @media(min-width: $mq-medium) {
      display: none;
    }

    @media(min-width: $mq-large) {
      display: inline;
    }
  }

}
