@import '../../global/refs.scss';

.services-section {
  width: 100%;
  min-height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 2rem;
  padding-bottom: 2rem;

  @media(min-width: $mq-medium) {
    padding-bottom: 4rem;
  }

  @media (min-width: $mq-large) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-top: 3rem;
    padding-bottom: 6rem;
  }

  @media(min-width: $mq-super-large) {
    padding-bottom: 7rem;
  }
}

.services-planets-dots-container {
  display: none;

  @media(min-width: $mq-large) {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 55%;
  }
}

.service-planets-dots-relative {
  position: relative;
  width: 42vw;
  height: 42vw;
  margin-left: 2%;

  img {
    width: 100%;
  }
}

.services-content-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    @include title;
    text-align: center;

    @media(min-width: $mq-large) {
      width: 100%;
      text-align: left;
    }
  }

  img {
    width: 140%;
    max-width: 28rem;
    margin-top: -5rem;
    margin-bottom: -5rem;

    @media (min-width: $mq-large) {
      display: none;
    }
  }

  @media (min-width: $mq-large) {
    width: 40%;
    margin-right: 7.5%;
  }
}

.carousel-slider-fadein {
  opacity: 1;
  transition: opacity 0.3s;
}

.carousel-slider-fadeout {
  opacity: 0;
  transition: opacity 0.3s;
}

.services-slide {
  min-height: 28rem;
  width: 100%;
  transition: all .3s ease-in-out;

  .services-slide-title {
    font-weight: bold;
    font-style: italic;
    font-size: 2.5rem;

    @media(min-width: $mq-large) {
      margin-bottom: 0.5rem;
    }
  
    span {
      font-size: 1rem;
    }
  }
  
  .services-slide-text {
    @include text;
    line-height: 2.2rem;
    font-weight: 600;

    @media(min-width: $mq-large) {
      font-size: 0.9rem;
    }
  }
}


.services-carousel-buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  button {
    background: transparent;
    border: none;
    color: $pink;
    font-weight: 700;
    font-style: italic;
    outline: none;
    font-size: 1.25rem;
  }
}

.deactivate-arrow {
  visibility: hidden;
}
