@import '../../global/refs.scss';

.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 40px;

  @media(min-width: $mq-small) {
    padding-top: 30px;
  }

  @media(min-width: $mq-medium) {
    padding-top: 40px;
    padding-bottom: 60px;
  }

  @media(min-width: $mq-large) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.banner-logo {
  width: 90px;

  @media(min-width: $mq-small) {
    width: 110px;
  }

  @media(min-width: $mq-medium) {
    width: 140px;
  }

  @media(min-width: $mq-large) {
    width: 160px;
  }
}

.banner-container {
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;

  @media(min-width: $mq-large) {
    width: 75%;
    margin-left: 5%;
    margin-top: 140px;
    flex-direction: row;
    align-items: flex-start;
    align-self: flex-start;
  }

  @media(min-width: $mq-super-large) {
    margin-top: 160px;
    width: 72%;
  }

  .banner-text {
    @include giant-title;
    margin-top: 120px;

    @media(min-width: $mq-large) {
      margin-top: 0;
    }

    p {
      margin-top: 10px;
      margin-bottom: 10px;
      overflow: visible;
    }

    .outline {
      color: transparent;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $white;
    }

    .regular {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $white;
    }

    .pink {
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
      background-image: url('./assets/pink-text-background.jpg');
      background-size: cover;
      padding-right: 5px;
    }

    .offset-1 {
      margin-left: 50px;

      @media(min-width: $mq-small) {
        margin-left: 70px;
      }

      @media(min-width: $mq-medium) {
        margin-left: 110px;
      }

      @media(min-width: $mq-large) {
        margin-left: 140px;
      }
    }

    .offset-2 {
      margin-left: 25px;

      @media(min-width: $mq-small) {
        margin-left: 35px;
      }

      @media(min-width: $mq-medium) {
        margin-left: 40px;
      }

      @media(min-width: $mq-large) {
        margin-left: 55px;
      }
    }

    .offset-3 {
      margin-left: 60px;

      @media(min-width: $mq-small) {
        margin-left: 80px;
      }

      @media(min-width: $mq-medium) {
        margin-left: 120px;
      }

      @media(min-width: $mq-large) {
        margin-left: 160px;
      }
    }
  }

  
  .banner-astronaut {
    width: 55%;
    max-width: 240px;
    margin-top: 100px;

    @media(min-width: $mq-small) {
      max-width: 260px;
    }

    @media(min-width: $mq-medium) {
      max-width: 280px;
    }

    @media(min-width: $mq-large) {
      width: 30%;
      max-width: 340px;
      margin-left: 80px;
      margin-top: 0;
    }

    @media(min-width: $mq-super-large) {
      width: 28%;
      max-width: 360px;
      margin-left: 100px;
    }
  }
}

.banner-blob {
  display: none;

  @media(min-width: $mq-large) {
    width: 20%;
    max-width: 400px;
    display: initial;
    position: absolute;
    top: 0;
    right: 0;
  }

  @media(min-width: $mq-super-large) {
    width: 18%;
  }
}