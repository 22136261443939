@import '../../global/refs.scss';

.tilted-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 6rem 0;

  @media(min-width: $mq-large) {
    margin: 8rem 0;
  }
}

.tilted-text {
  width: 80%;
  text-align: center;
  line-height: 2.75rem;
  font-size: 1.4rem;
  transform: matrix(1, -0.03, 0.03, 1, 0, 0);
  text-transform: uppercase;
  font-weight: 600;

  @media(min-width: $mq-large) {
    font-size: 1.9rem;
    line-height: 3.15rem;
    width: 50%;
  }
}
