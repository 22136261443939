@import '../../global/refs.scss';

.service-planet-dot {
  width: 2.55rem;
  height: 2.55rem;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    line-height: 1.2rem;
    font-style: italic;
    font-weight: 700;
    position: absolute;
  }
}
